import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Bleeding Edge PC Parts for a Top of the Line November 2020 Build",
  "date": "2020-11-10",
  "slug": "blog/bleeding-edge-pc-parts-for-a-top-of-the-line-november-2020-build",
  "featuredImage": "./images/bleeding-edge.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`CPU Pick`}</h2>
    <p>{`AMD Ryzen 5950x`}</p>
    <p>{`Check availability on all of the latest `}<a parentName="p" {...{
        "href": "/hot-list/gaming/desktop-processors/Ryzen5000"
      }}>{`AMD Ryzen 5000 Series CPUs here.`}</a></p>
    <hr></hr>
    <h2>{`Motherboard Pick`}</h2>
    <p>{`An x570 motheroard with PCIE 4.0 Support.`}</p>
    <hr></hr>
    <h2>{`Graphics Card Pick`}</h2>
    <p>{`Nvidia RTX 3090`}</p>
    <p>{`Check availability on all of the latest `}<a parentName="p" {...{
        "href": "/hot-list/gaming/graphics-cards/nvidia-geforce-rtx-3090"
      }}>{`Nvidia 30 Series GPUs here.`}</a></p>
    <hr></hr>
    <h2>{`RAM Pick`}</h2>
    <p>{`We're still researching the best RAM as new models have just released. Sit tight and we'll place our pick here soon.`}</p>
    <hr></hr>
    <h2>{`Storage Pick`}</h2>
    <p>{`Samsung 980 Pro 1TB.`}</p>
    <hr></hr>
    <h2>{`Cooling Picks`}</h2>
    <p>{`Air: Noctua NH-D15
Water: We're still researching the best AIO water coolers as new models have recently released. Sit tight and we'll place our pick here soon.`}</p>
    <hr></hr>
    <h2>{`PSU Pick`}</h2>
    <p>{`There are many options, but since we're going top of the line here, we'd pick a Titanium rated PSU.`}</p>
    <hr></hr>
    <h2>{`Case Picks`}</h2>
    <p>{`ITX: TBD
Miicro ATX: TBD
ATX: The Lian Li 011D has been receiving great fanfare lately for high end builds.
E-ATX: TBD`}</p>
    <hr></hr>
    <h2>{`Display Picks`}</h2>
    <p>{`4k 144hz LG Monitor`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      